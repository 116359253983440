import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import {Button} from '@material-ui/core';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { mainListItems,secondaryListItems } from './listItems';
//import Chart from './Chart';
import Axios from 'axios';
import Iframe from 'react-iframe'
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import DashboardIcon from "@material-ui/icons/Dashboard";
import MemoryRoundedIcon from "@material-ui/icons/MemoryRounded"
import ListItemText from "@material-ui/core/ListItemText";
import EqualizerRoundedIcon from '@material-ui/icons/EqualizerRounded';
import TrackChangesRoundedIcon from '@material-ui/icons/TrackChangesRounded';
import BlurCircularRoundedIcon from '@material-ui/icons/BlurCircularRounded';
import FilterTiltShiftRoundedIcon from '@material-ui/icons/FilterTiltShiftRounded';
import Newrelic from "./Charts/Newrelic";
import Disk from "./Charts/Disk";
import SSM from "./Charts/SSM";
import Soon from "./Charts/Soon";
import Memory from "./Charts/Memory"
import CPU from "./Charts/CPU";
import Agent from "./Charts/Agent";
import ListSubheader from "@material-ui/core/ListSubheader";
import AssignmentIcon from "@material-ui/icons/Assignment";
import Qualys from "./Charts/Qualys";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="#">
        Application Engineering
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}
function handler(){
  return(
    Axios.get("http://ec2statistics.s3-website-us-east-1.amazonaws.com/plot1").then(resp => window.Bokeh.embed.embed_item(resp.data, 'testPlot'))
  )
}
const drawerWidth = 200;

const useStyles = makeStyles((theme) => ({
  root: {
    background: "white",

    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed

  },
  toolbarIcon: {

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    background: "#047b9c",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 30,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    background: "#047b9c",
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    background: theme.background,
  },
  paper: {
    padding: theme.spacing(1),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  cHeight:{
    height: 375,
  },
  cWidth:{
    width : 615,
  },

}));

export default function Dashboard() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleClick = () => {
    return(<Newrelic/>)

}
  const [component, setComponent] = useState('Dashboard')


  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const customPaper = clsx(classes.paper,classes.cHeight, classes.cWidth)
  return (

    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>

          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>SoloView</Typography>
          { component === 'newrelic' ? <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>Newrelic Status</Typography>
          : component === 'ssm' ? <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>SSM Status</Typography>
          : component === 'qualys' ? <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>Qualys Status</Typography>
          : component === 'soon' ? <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>Coming Soon</Typography>
          : component === 'disk' ? <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>EC2 Disk Space Status</Typography>
          : component === 'cpu' ? <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>EC2 CPU Alert Statistics</Typography>
          : component === 'memory' ? <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>EC2 Memory Alert Statistics</Typography>
          : component === 'agent' ? <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>EC2 Agent Overall Summary</Typography>
          : <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>EC2 Disk Space Status</Typography>
          }
          {/*
          <IconButton color="inherit">
            <Badge badgeContent={4} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton>
           */}
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
          <div>
            <ListItem button onClick={() => setComponent('disk')}   >
              <ListItemIcon>
                <TrackChangesRoundedIcon    style={{ color: "white" }}  />
              </ListItemIcon>
              <ListItemText primary={<Typography type="body2" style={{ color: '#FFFFFF' }}>EC2 Disk</Typography>} />
            </ListItem>
              <ListItem button onClick={() => setComponent('memory')}>
              <ListItemIcon>
                <MemoryRoundedIcon style={{ color: "white" }} />
              </ListItemIcon>
              <ListItemText primary={<Typography type="body2" style={{ color: '#FFFFFF' }}>EC2 Memory</Typography>} />
            </ListItem>
              <ListItem button onClick={() => setComponent('cpu')}>
              <ListItemIcon>
                <BlurCircularRoundedIcon  style={{ color: "white" }}/>
              </ListItemIcon>
              <ListItemText primary={<Typography type="body2" style={{ color: '#FFFFFF' }}>EC2 CPU</Typography>} />
            </ListItem>
          </div>
        </List>
         <List>
           <div>
             <ListItem button onClick={() => setComponent('agent')} >
                <ListItemIcon>
                  <EqualizerRoundedIcon style={{ color: "white" }} />
                </ListItemIcon>
                <ListItemText primary={<Typography type="body2" style={{ color: '#FFFFFF' }}>Agents Summary</Typography>} />
              </ListItem>
              <ListItem button onClick={() => setComponent('newrelic')} >
                <ListItemIcon>
                  <FilterTiltShiftRoundedIcon style={{ color: "white" }} />
                </ListItemIcon>
                <ListItemText primary={<Typography type="body2" style={{ color: '#FFFFFF' }}>New Relic</Typography>} />
              </ListItem>
              <ListItem button onClick={() => setComponent('ssm')} >
                <ListItemIcon>
                  <FilterTiltShiftRoundedIcon style={{ color: "white" }} />
                </ListItemIcon>
                <ListItemText primary={<Typography type="body2" style={{ color: '#FFFFFF' }}>SSM</Typography>} />
              </ListItem>
              <ListItem button onClick={() => setComponent('qualys')}>
                <ListItemIcon>
                  <FilterTiltShiftRoundedIcon style={{ color: "white" }} />
                </ListItemIcon>
                <ListItemText primary={<Typography type="body2" style={{ color: '#FFFFFF' }}>Qualys</Typography>} />
              </ListItem>
            </div>
         </List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          { component === 'newrelic' ? <Newrelic/> : component === 'ssm' ? <SSM/>
          : component === 'qualys' ? <Qualys/>
          : component === 'memory' ? <Memory/>
          : component === 'cpu' ? <CPU/>
          : component === 'disk' ? <Disk/>
          : component === 'agent' ? <Agent/>
          : <Disk/>
          }
          {/*
          <Grid container spacing={3}>
            <Grid item xs={12} md={8} lg={9}>
              <Paper className={fixedHeightPaper}>
                <Chart />
              </Paper>
            </Grid>
            {/* Recent Deposits
            <Grid item xs={12} md={4} lg={3}>
              <Paper className={fixedHeightPaper}>
                <Deposits />
              </Paper>
            </Grid>
             Recent Orders
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Orders />
              </Paper>
              </Grid>
            </Grid>*/}
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
}